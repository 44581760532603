import React, { useState } from "react"
import {
  Container,
  TextField,
  CircularProgress,
  Button,
  Grid,
  Typography,
  makeStyles,
  Paper,
  CardMedia,
  CardContent,
  Card,
  useMediaQuery,
} from "@material-ui/core"
import Hero from "../components/hero"
import axios from "axios"
import Delivery from "../img/delivery.jpg"
import Voucher from "../img/voucher.jpg"
import Sky from "../img/pamper2.jpg"

const useStyles = makeStyles(theme => ({
  submissionText: {
    fontSize: "1.2rem",
    fontFamily: "Nunito",
    padding: "50px",
    textAlign: "center",
  },
  bottom: {
    paddingBottom: "20px",
  },
  mainPaper: {
    backgroundColor: "#ffffffdd",
  },
  bodyText: {
    fontSize: "1.2rem",
    fontFamily: "Open Sans",
    textAlign: "center",
    padding: "20px",
  },
  cardCaption: {
    fontFamily: "Open Sans",
    textAlign: "center",
  },
  card: {
    borderRadius: "25px",
  },
}))

function PamperForm() {
  const classes = useStyles()
  const [name, setName] = useState("")
  const [number, setNumber] = useState("")
  const [nomName, setNomName] = useState("")
  const [nomNumber, setNomNumber] = useState("")
  const [text, setText] = useState("")
  const [formState, setFormState] = useState("initial")

  const LinkCheck = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
  const numCheck = /^\d{11}$/
  let responseObj = null

  function handleSubmit() {
    setFormState("loading")
    axios
      .post(
        "https://gi9tdplnub.execute-api.us-east-1.amazonaws.com/default/pamper",
        {
          name: name,
          number: number,
          nomName: nomName,
          nomNumber: nomNumber,
          text: text,
        }
      )
      .then(
        response => {
          console.log(response)
          responseObj = response.data
          if (responseObj.status == "success") {
            setFormState("done")
          } else {
            setFormState("error")
          }
        },
        error => {
          setFormState("error")
        }
      )
  }

  if (formState == "loading") {
    return (
      <Grid container spacing={0} alignItems="center" justify="center">
        <br />
        <CircularProgress size={150} thickness={1.5} />
        <br />
      </Grid>
    )
  } else if (formState == "error") {
    if (responseObj) {
      return (
        <div>
          <hr />
          <Typography className={classes.submissionText}>
            Submission Error.{responseObj.info}
          </Typography>
        </div>
      )
    } else {
      return (
        <div>
          <hr />
          <Typography className={classes.submissionText}>
            Submission Error. You may have made too many requests. Please try
            again later.
          </Typography>
        </div>
      )
    }
  } else if (formState == "done") {
    return (
      <Grid container spacing={0} alignItems="center" justify="center">
        <br />
        <Typography className={classes.submissionText}>
          Thank you for your submission! Your email has been sent to the team.
        </Typography>
        <br />
      </Grid>
    )
  } else {
    return (
      <Container>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="pamper-name"
          label="Your Name"
          name="pamper-name"
          error={LinkCheck.test(name)}
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <br />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="num-1"
          label="Your Mobile (without spaces)"
          name="num-1"
          error={!numCheck.test(number)}
          helperText="Please enter a valid UK mobile number (without spaces)."
          value={number}
          onChange={e => setNumber(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="nom-name"
          label="Nominee's Name"
          name="nom-name"
          error={LinkCheck.test(nomName)}
          value={nomName}
          onChange={e => setNomName(e.target.value)}
        />
        <br />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="num-2"
          label="Nominee's Mobile (without spaces)"
          name="num-2"
          error={!numCheck.test(nomNumber)}
          helperText="Please enter a valid UK mobile number (without spaces)."
          value={nomNumber}
          onChange={e => setNomNumber(e.target.value)}
        />
        <br />
        <TextField
          variant="outlined"
          required
          fullWidth
          id="general-text"
          label="Reasons for Nomination"
          placeholder="Your message here..."
          multiline
          rows={8}
          margin="normal"
          error={LinkCheck.test(text)}
          value={text}
          onChange={e => setText(e.target.value)}
        />
        <br />
        <div className={classes.bottom}>
          {name && number && nomName && nomNumber && text ? (
            <Button
              className={classes.submit}
              variant="contained"
              style={{
                backgroundColor: "#0072ab",
                color: "white",
                borderRadius: "50px",
              }}
              color="primary"
              onClick={() => handleSubmit()}
            >
              Submit Form
            </Button>
          ) : (
            <Button
              className={classes.submit}
              style={{ borderRadius: "50px" }}
              variant="contained"
              color="primary"
              disabled
            >
              Enter missing details to submit
            </Button>
          )}
        </div>
      </Container>
    )
  }
}

export default function Pamper() {
  const classes = useStyles()
  const isXl = useMediaQuery("(min-width: 1200px)")

  return (
    <div id="pamper">
      <Container>
        <Container maxWidth="md">
          <Hero
            title="Aston's Pamper Project"
            bodyText="Treat that deserving person in your life!"
          />
          <Typography className={classes.bodyText}>
            Born from Aston's idea to reach out to other local charities and
            deserving people, Aston's Pamper Project is a venture designed to
            give back to members of the community. He hopes for it to grow and
            assist as many people as possible.{" "}
            <strong>Simply nominate someone deserving in your life</strong>, and
            they could win the choice of a luxury spa day in Rayleigh, or a
            special pamper hamper!{" "}
            <strong>This can be a group or individual</strong>, anyone you think
            is deserving!
          </Typography>
        </Container>
        <br />
        <Grid
          container
          spacing={0}
          alignItems="center"
          justify="center"
          direction="row-reverse"
        >
          <Grid item sm={6}>
            <Typography className={classes.bodyText}>
              For groups, we offer everything a large group would want on their
              breaks, including tea, coffee, biscuits, sweets, cakes, fizz, and
              various treats for their tea room! We've delivered these to
              organisations such as the fire service, care homes, and hospices.
            </Typography>
          </Grid>
          <Grid item sm={6} lg={isXl ? 3 : 7}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.cardMedia}
                component="img"
                alt="Team Avery delivering a Pamper Hamper."
                image={Delivery}
                title="The Vie Aesthetics Salon"
              />
              <CardContent>
                <Typography className={classes.cardCaption}>
                  Team Avery delivering a group Pamper Hamper.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={0} alignItems="center" justify="center">
          <Grid item sm={7}>
            <Typography className={classes.bodyText}>
              We also offer{" "}
              <a
                href="https://www.one4all.com/"
                target="_blank"
                rel="noreferrer noopen"
              >
                One4All Vouchers
              </a>{" "}
              for individuals who would like to purchase their own products!
              We'll even throw in a bottle of fizz and some chocolates for those
              who choose this option.
            </Typography>
            <Typography className={classes.bodyText}>
              Another one of our popular options is to receive a link to select
              your own Avon products!
            </Typography>
          </Grid>
          <Grid item sm={6} lg={isXl ? 3 : 7}>
            {" "}
            <Card className={classes.card}>
              <CardMedia
                className={classes.cardMedia}
                component="img"
                alt="Beccy Curry with her prize"
                image={Voucher}
                title="The Vie Aesthetics Salon"
              />
              <CardContent>
                <Typography className={classes.cardCaption}>
                  One of our winners, Beccy Curry, receiving her hand delivered
                  voucher and gifts.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          alignItems="center"
          justify="center"
          direction="row-reverse"
        >
          <Grid item sm={6}>
            <Typography className={classes.bodyText}>
              The lucky individual nominees can also choose to receive a
              luxurious pamper session at{" "}
              <a
                href="http://www.vie-aesthetics.com/"
                rel="noopen noreferrer"
                target="_blank"
              >
                Vie Aesthetics
              </a>{" "}
              in Rayleigh, or a special pamper hamper packed with self care
              items, including creams, face masks, and bath products, along with
              some fizz and confectionary for good measure.
            </Typography>
          </Grid>
          <Grid item sm={6} lg={isXl ? 3 : 7}>
            {" "}
            <Card className={classes.card}>
              <CardMedia
                className={classes.cardMedia}
                component="img"
                alt="Sky receiving her spa pampering"
                image={Sky}
                title="Sky receiving her spa pampering"
              />
              <CardContent>
                <Typography className={classes.cardCaption}>
                  Sky, a past winner, enjoying her spa pampering day.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Typography className={classes.bodyText}>
        Nominate that special person or group using the form below!
      </Typography>
      <PamperForm />
      <div style={{ height: "5vh" }} />
    </div>
  )
}
